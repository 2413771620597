/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "normalize.css"
import "./layout.scss"

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => {
        return (
          <div className="site-wrapper">
            <Header
              siteTitle={data.site.siteMetadata.title}
              onBeacon={(title, breadcrumb) => {}}
            />
            <main className="main">{children}</main>
            <footer className="footer">
              <div className="footer__brand">
                台灣區瀝青工業同業公會 © {new Date().getFullYear()}
              </div>
              <table className="footer__contact">
                <tbody>
                  <tr>
                    <td>電話:</td>
                    <td>(02) 2395-6668</td>
                    <td>傳真:</td>
                    <td>(02) 2395-8008</td>
                  </tr>
                  <tr>
                    <td>地址:</td>
                    <td>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://maps.app.goo.gl/WCgAfMpsKxKhdZs99"
                      >
                        台北市中正區青島東路4號2樓之7
                      </a>
                    </td>
                    <td>E-mail:</td>
                    <td>
                      <a href="mailto:taia2.mail@msa.hinet.net">
                        taia2.mail@msa.hinet.net
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </footer>
          </div>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
