import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { navigate } from "gatsby"

import logo2 from "../images/logo2.gif"
import logoNewTrans from "../images/logonew-transparent.gif"
// import imgRoad from "../images/hd/road-cropped.jpg"

const customStyle = {
  // backgroundImage: `url(${imgRoad})`,
  // backgroundSize: "cover",
  // backgroundRepeat: "no-repeat",
  // backgroundPosition: "center center",
}

const Header = ({ siteTitle, onBeacon }) => (
  <header className="header" style={customStyle}>
    <div className="header__logo" onClick={() => navigate("/")}>
      <img alt="logo" src={logoNewTrans} />
      <img alt="banner" src={logo2} />
    </div>
    <nav className="header__nav">
      <Link
        className="header__link"
        to="/"
        onClick={() => onBeacon("最新消息", "/最新消息")}
      >
        最新消息
      </Link>
      <div className="header__link">
        公會簡介
        <div className="header__sub-nav">
          <Link
            className="header__sub-nav-link"
            to="/introduction/history"
            onClick={() => onBeacon("公會沿革", "/公會簡介/公會沿革")}
          >
            公會沿革
          </Link>
          <Link
            className="header__sub-nav-link"
            to="/introduction/bylaws"
            onClick={() => onBeacon("公會章程", "/公會簡介/公會章程")}
          >
            公會章程
          </Link>
          <Link
            className="header__sub-nav-link"
            to="/introduction/organization"
            onClick={() => onBeacon("組織服務", "/公會簡介/組織服務")}
          >
            組織服務
          </Link>
          <Link
            className="header__sub-nav-link"
            to="/introduction/director"
            onClick={() => onBeacon("理監事", "/公會簡介/理監事")}
          >
            理監事
          </Link>
          <Link
            className="header__sub-nav-link"
            to="/introduction/future"
            onClick={() => onBeacon("公會展望", "/公會簡介/公會展望")}
          >
            公會展望
          </Link>
        </div>
      </div>
      <div className="header__link">
        產業訊息
        <div className="header__sub-nav">
          <Link
            className="header__sub-nav-link"
            to="/industry/law"
            onClick={() => onBeacon("法令政策", "/產業訊息/法令政策")}
          >
            法令政策
          </Link>
          <Link
            className="header__sub-nav-link"
            to="/industry/technology"
            onClick={() => onBeacon("技術通報", "/產業訊息/技術通報")}
          >
            技術通報
          </Link>
          <Link
            className="header__sub-nav-link"
            to="/industry/product"
            onClick={() => onBeacon("最新產品", "/產業訊息/最新產品")}
          >
            最新產品
          </Link>
          <Link
            className="header__sub-nav-link"
            to="/property/main"
            onClick={() => onBeacon("他山之石", "/產業訊息/他山之石")}
          >
            他山之石
          </Link>
          <Link
            className="header__sub-nav-link"
            to="/property/download"
            onClick={() => onBeacon("檔案下載", "/產業訊息/檔案下載")}
          >
            檔案下載
          </Link>
          <Link
            className="header__sub-nav-link"
            to="/property/course"
            onClick={() => onBeacon("相關課程", "/產業訊息/相關課程")}
          >
            相關課程
          </Link>
        </div>
      </div>
      <div className="header__link">
        會員介紹
        <div className="header__sub-nav">
          <Link
            className="header__sub-nav-link"
            to="/associator/list"
            onClick={() => onBeacon("會員名錄", "/會員介紹/會員名錄")}
          >
            會員名錄
          </Link>
          <Link
            className="header__sub-nav-link"
            to="/associator/select"
            onClick={() => onBeacon("每週一廠", "/會員介紹/每週一廠")}
          >
            每週一廠
          </Link>
          <Link
            className="header__sub-nav-link"
            to="/associator/join"
            onClick={() => onBeacon("加入會員", "/會員介紹/加入會員")}
          >
            加入會員
          </Link>
        </div>
      </div>
      <Link
        className="header__link"
        to="/activity"
        onClick={() => onBeacon("活動花絮", "/活動花絮")}
      >
        活動花絮
      </Link>
      <Link
        className="header__link"
        to="/newsletter"
        onClick={() => onBeacon("瀝青簡訊", "/瀝青簡訊")}
      >
        瀝青簡訊
      </Link>
      <Link
        className="header__link"
        to="/website"
        onClick={() => onBeacon("相關網站", "/相關網站")}
      >
        相關網站
        {/* <div className="header__sub-nav">
          <Link className="header__sub-nav-link" to="/website/gov-and-non-gov">
            政府&民間單位
          </Link>
          <Link className="header__sub-nav-link" to="/website/local-gov">
            各縣市政府機關
          </Link>
          <Link className="header__sub-nav-link" to="/website/edu">
            學術單位
          </Link>
          <Link className="header__sub-nav-link" to="/website/international">
            國外相關網站
          </Link>
        </div> */}
      </Link>
      {/* <Link
        className="header__link"
        to="/contact"
        onClick={() => onBeacon("聯絡公會", "/聯絡公會")}
      >
        聯絡公會
      </Link> */}
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
