import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const fmtDate = dateStr => {
  const date = new Date(dateStr)
  let mm = date.getMonth() + 1
  mm = (mm > 9 ? "" : "0") + mm
  return `${date.getFullYear() - 1911}.${mm}`
}

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div className="home">
      <div className="home__news">
        <div className="home__news-head">最新消息</div>
        <div className="home__news-list">
          {data.allStrapiNews.edges.map(({ node }) => (
            <div className="home__news-list-item" key={node.strapiId}>
              <div className="home__news-list-item-date">
                {fmtDate(node.publishDate)}
              </div>
              <div className="home__news-list-item-text">
                {node.content}
                <div className="home__news-list-item-links">
                  {node.links.map(link => (
                    <a
                      key={link.id}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.url}
                    >
                      {link.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allStrapiNews(
      filter: { category: { eq: "news" } }
      sort: { fields: [publishDate], order: [DESC] }
    ) {
      edges {
        node {
          strapiId
          content
          publishDate
          links {
            name
            id
            url
          }
        }
      }
    }
  }
`
